<template>
  <div>
    <common-hero></common-hero>
    <div class="desc-wrapper about-wrapper">
      <vue-custom-scrollbar class="scroll-area">
        <div class="about-section">
          <div>
            <h2>मेल्लेखको इतिहास</h2>
            <p>
              २०४६ को जनआन्दोलन पश्चात्त सम्पन्न २०४९ को स्थानीय निर्वाचनमा यस
              ईलाकाबाट कांग्रेसको सहयोगमा हालको वार्ड नं. ५ निवासी मोति अधिकारी
              ईलाका सदस्यमा निर्वाचित भएका थिए भने २०५४ सालमा सम्पन्न स्थानीय
              निर्वाचनमा यस ईलाकाबाट तत्कालिन नेकपा (एमाले) उम्मेदवार वार्ड नं. ३
              निवासी हेमराज शाह ईलाका सदस्य तथा वार्ड नं. ६ निवासी कृष्णप्रसाद
              जैशी जिविसको सभापतिमा निर्वाचित भएका थिए । पुरानो राजावादी सोच,
              समन्तवादका विरुद्धको लडाँई ,दमन, थिचोमिचो, निरंकुशता, तथा खोले
              टाकुरे सामन्तहरुको पञ्जाबाट मुक्त हुदै यस ईलाकामा हालको हात्तिकोट
              बाहेक सबै वडाहरुमा (विगतका गाविसहरुमा) तत्कालिन एमाले उम्मेदवारहरु
              विजयी हुदा जनतामा निकै उत्साह छाएको थियो । यहाँको जनजिवन र विकास पनि
              राष्ट्रिय राजनीतिसंग सम्बन्धित भएको हुदा मुलुकको तत्कालिन परिवेशलाई
              समेत ध्यान दिईनु पर्दछ । २०५२ साल फाल्गुण २ गतेबाट तत्कालिन
              माओवादीले राज्य विरुद्धको शसश्त्र संघर्ष सुरु ग¥यो । खास गरी २०५४ मा
              निर्वाचित स्थानीय सरकारको म्याद संविधानतः २०५९ मा समापन भएतापनि विषम
              राजनीतिक परिस्थितिमा चुनाव हुन नसक्ने अवस्थाको लेखाजोखा गरी राजनीतिक
              सहमतिका आधारमा १ वर्ष म्याद थप्ने तत्कालिन एमाले र कांग्रेसको
              प्रस्ताव तत्कालिन प्रधानमन्त्री देउवाद्धारा अस्विकार गरियो जसले
              राजनीति अवस्था थप तरल र अन्यौलता तर्फ धकेलियो । यस विच राज्य र
              माओवादी पक्षबाट थुप्रै जनधनको क्षेती भयो । यस ईलाका तथा हालको
              मेल्लेख गाउँपालिकाका वार्ड नं. ८ बाहेक सबै वडाहरुमा तत्कालिन
              जनयुद्धको प्रयाप्त प्रभाव रहयो । राजनीतिक हिसाबले गाउँमा कम्युनिष्ट
              बाहेक मान्छे भेटिदैन थे । कोहि विस्थापितका नाममा गाउँ छोड्न पुगे,
              कोहि केहि वा अन्य हिसाबले गाउँ छोडेको भेटिन्छ । उक्त क्रम १० वर्ष
              चल्यो । उक्त संघर्षको मैदानमा हतियार सहित अहिलेका गाउँपालिका अध्यक्ष
              लोकबहादुर बोहरा सहितका युवाहरुको सशक्त भूमिका छ । मुलुक स्थानीय
              सरकार विहिन भयो । विकास निर्माण र नीति निर्माण लथालिङ्ग वन्यो ।
              जिल्ला सदरमुकाम समेत २०५८ मा ध्वस्त भयो । गाउँबाट सदरमुकाम आवत जावत
              दोहोरो अनुमतिमा हुन थाल्यो । यस ईलाका अप्ठेरो परिस्थतिमा जिल्ला
              सदरमुकाम मंगलसेनमा यहाँका जनताको सुखदुःमा आवश्यक सहयोगका लागि
              शेरबहादुर कुवँर (राष्ट्रिय सभा सदस्य तथा एमाले अध्यक्ष) को भूमिका
              ठूलो छ भने यसै गाउँपालिका वार्ड नं. ६ निवासी हिरण्ड भट्टराई (नेकपा–
              गापा संयोजक तथा सुपखेविस कोषाध्यक्ष) पनि यस अभियानमै सहयोगी थिए जुन
              यस क्षेत्रका निम्ति हुने हरेका गतिविधिमा समाबेश हुने गर्दथे । यो
              क्रम २०६२÷०६३ सम्म चल्यो । तत्कालिन राजा ज्ञानेन्द्र निरंकुशता
              विरुद्धको आन्दोलनमा सातवटा राजनीति दल र माओबादी वीच राजनीतिक
              एजेण्डामा सहमति गरि गणतन्त्र स्थापनार्थ २०६२ मंशिर ७ गते बा¥हबुंदे
              समझदारी बन्यो । अब राजा एका तिर भए भने राजनीतिक दल अर्को तर्फ ।
              नेपालको राजनीतिक दुई ध्रुवमा केन्द्रित भयो । राष्ट्रिय–
              अन्तरराष्ट्रिय मान्यता प्राप्त आन्दोलन (२०६२ चैत्र २२ देखि २०६३
              बैशाष ११ गते) १९ दिनसम्म चलेको थियो । आन्दोलनमा २० जनाले सहादत
              प्राप्त गरे । त्यस आन्दोलनमा यस गाउँपालिकाबाट समेत व्यापक जनसहभागिता
              जुटेको थियो । सात राजनीतिक दलको मार्गचित्र बमोजिम २०५९ विघटन गरिएको
              संसदको पुर्नस्थापनसंगै ११ गतेको घोषणा पछि नेपालमा लोकतन्त्रको
              स्थापना भएको हो ।
            </p>
            <!-- <a href="#" class="see-more">
              थप जानकारी
              <unicon name="sign-out-alt"></unicon>
            </a> -->
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  name: "Naming",
  components: {
    CommonHero: () => import("./commonHero.vue"),
    vueCustomScrollbar
  }
};
</script>

<style lang="scss" scoped>

</style>
